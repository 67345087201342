<!-- Error demo pages -->
<template>
  <transition name="fade-in-up">
    <router-view></router-view>
  </transition>
</template>

<script>
  export default {
    components: {}
  };
</script>

<style lang="scss">
  .error-page-container {
    background-image: url('/media/error/bg1.jpg');
  }
  .error-page-title {
    font-size: 20rem;
  }

  @media (max-width: 1903px) {
    .error-page-title {
      font-size: 15rem;
    }
  }

  @media (max-width: 1263px) {
    .error-page-title {
      font-size: 13rem;
    }
  }

  @media (max-width: 600px) {
    .error-page-title {
      font-size: 10rem;
    }
  }
</style>
